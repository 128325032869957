/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.feature-tab.active {
  @apply bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10; 
}
.feature-tab.active > h3 > button {
  @apply text-blue-600 lg:text-white; 
}
.feature-tab.active > p {
  @apply text-white;
}

.feature-tab.inactive {
  @apply hover:bg-white/10 lg:hover:bg-white/5;
}
.feature-tab.inactive > h3 > button {
  @apply text-blue-100 hover:text-white lg:text-white; 
}
.feature-tab.inactive > p {
  @apply text-blue-100; 
}

.secondary-feature-tab.active { }
.secondary-feature-tab.inactive { @apply opacity-75 hover:opacity-100 }

.secondary-feature-tab.active > .icon { @apply bg-blue-600 }
.secondary-feature-tab.inactive > .icon { @apply bg-slate-500 }
 
.secondary-feature-tab.active h3 { @apply text-blue-600 }
.secondary-feature-tab.inactive h3 { @apply text-slate-600 }

.secondary-feature-panel.active {}
.secondary-feature-panel.inactive {}

#secondary-features div[data-controller='tabs'][data-tabs-index-value='0'] .secondary-feature-panel {
  transform: translateX(0%);
}
#secondary-features div[data-controller='tabs'][data-tabs-index-value='1'] .secondary-feature-panel {
  transform: translateX(-100%);
}
#secondary-features div[data-controller='tabs'][data-tabs-index-value='2'] .secondary-feature-panel {
  transform: translateX(-200%);
}
